<template>
    <div>
        <div class="row">
            <div class="col-xl-6 offset-xl-3">
                <content-card>
                    <template #body>
                        <div>
                            <h2 class="text-center">
                                {{
                                    model.id
                                        ? "Edit Cancellation Blackout Date"
                                        : "Create Cancellation Blackout Date"
                                }}
                            </h2>
                            <v-form
                                v-if="rules"
                                @submit="onSubmit"
                                :initial-values="model"
                                :validation-schema="rules"
                                v-slot="{ isSubmitting }"
                            >
                                <div class="row mt-3">
                                    <div class="col">
                                        <val-form-input
                                            v-model="model.blackoutDate"
                                            :rules="rules.blackoutDate"
                                            v-slot="{ valid, inValid }"
                                            :name="
                                                rules.blackoutDate.propertyName
                                            "
                                        >
                                            <div
                                                class="
                                                    input-group
                                                    has-validation
                                                "
                                            >
                                                <date-picker
                                                    v-model="blackoutDate"
                                                    :name="
                                                        rules.blackoutDate
                                                            .propertyName
                                                    "
                                                    :format="'MM/dd/yyyy'"
                                                    :format-placeholder="{
                                                        year: 'YYYY',
                                                        month: 'MM',
                                                        day: 'DD'
                                                    }"
                                                    class="form-control"
                                                    :class="{
                                                        'is-valid': valid,
                                                        'is-invalid': inValid,
                                                        'is-invalid-border':
                                                            inValid
                                                    }"
                                                ></date-picker>
                                            </div>
                                            <error-message
                                                :name="
                                                    rules.blackoutDate
                                                        .propertyName
                                                "
                                                class="text-danger"
                                            ></error-message>
                                        </val-form-input>
                                    </div>
                                </div>
                                <div class="row align-items-end mt-4">
                                    <div class="col-lg-12 text-end">
                                        <router-link
                                            :to="
                                                routePaths.cancellationBlackoutDatePath
                                            "
                                            class="
                                                btn btn-gray btn-gray-outline
                                                me-2
                                            "
                                        >
                                            Cancel
                                        </router-link>
                                        <button
                                            :disabled="isSubmitting"
                                            class="btn btn-primary"
                                            type="submit"
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            </v-form>
                        </div>
                    </template>
                </content-card>
            </div>
        </div>
    </div>
</template>
<style scoped>
.is-invalid-border {
    border-style: solid;
    border-width: 1px;
}
</style>
<script lang="ts">
import { defineComponent, onMounted, ref, computed } from "vue";
import { Form, ErrorMessage } from "vee-validate";

import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { useRouter } from "vue-router";
import { CancellationBlackoutDateModel } from "@/models";
import { restClient } from "@/store/actions";
import { ValFormInput, hasErrors } from "@elite/validation";
import { routePaths } from "@/router";
import ContentCard from "@/components/ContentCard.vue";
import { AppGetterTypes, useStore } from "@/store";
export default defineComponent({
    components: {
        "v-form": Form,
        "val-form-input": ValFormInput,
        "error-message": ErrorMessage,
        "date-picker": DatePicker,
        "content-card": ContentCard
    },
    props: {
        cancellationBlackoutDateId: { type: Number, required: true }
    },
    setup(props) {
        const { getters } = useStore();

        const rules = computed(
            () =>
                getters[AppGetterTypes.validationRules]
                    ?.cancellationBlackoutDateModel
        );

        const router = useRouter();
        const model = ref<CancellationBlackoutDateModel>({
            id: props.cancellationBlackoutDateId,
            blackoutDate: undefined
        });

        const isNew = props.cancellationBlackoutDateId === 0;
        const blackoutDate = computed({
            get: () => {
                if (model.value.blackoutDate) {
                    return new Date(model.value.blackoutDate);
                }
                return undefined;
            },
            set: (val) => {
                if (val) {
                    model.value.blackoutDate = val.toJSON();
                }
            }
        });

        onMounted(async () => {
            if (!isNew) {
                const response =
                    await restClient.getJson<CancellationBlackoutDateModel>(
                        `/api/admin/CancellationBlackoutDate/${props.cancellationBlackoutDateId}`
                    );

                if (response.data) {
                    model.value = response.data;
                    if (response.data.blackoutDate) {
                        model.value.blackoutDate = response.data.blackoutDate;
                    }
                }
            }
        });

        // Submit
        const onSubmit = async (): Promise<void> => {
            if (!model.value.blackoutDate) {
                return;
            }
            //  Save cancellation blackout date data
            const response = isNew
                ? await restClient.postJson<CancellationBlackoutDateModel>(
                      "/api/admin/CancellationBlackoutDate",
                      model.value
                  )
                : await restClient.putJson<CancellationBlackoutDateModel>(
                      "/api/admin/CancellationBlackoutDate",
                      model.value
                  );

            if (hasErrors(response)) {
                return;
            }

            router.push(routePaths.cancellationBlackoutDatePath);
        };

        return {
            onSubmit,
            blackoutDate,
            model,
            routePaths,
            isNew,
            rules
        };
    }
});
</script>
